import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import styles from "./MailchimpForm.module.scss";

const MailchimpForm = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(email)
      .then((data) => {
        if (data.result == "success") {
          alert("You've been added to the mailing list!");
        } else if (data.result == "error") {
          alert(
            "Email subscription failed. Your email might be on the mailing list already. Kindly verify your email subscription."
          );
        }
      })
      .catch((error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit} className={styles["mailchimpForm"]}>
      <h3 className={styles["mailchimpForm__subscribe-title"]}>Subscribe</h3>
      <p className={styles["mailchimpForm__subscribe-content"]}>
        For new contents or freebies, will send them direct to your inbox. :)
      </p>
      <div className={styles["mailchimpForm__wrapper"]}>
        <input
          className={styles["mailchimpForm__input"]}
          placeholder="Email address"
          name="email"
          type="text"
          onChange={handleEmailChange}
        />
        <button type="mailchimpForm__submit">Subscribe</button>
      </div>
    </form>
  );
};

export default MailchimpForm;
