// @flow
import { useSiteMetadata } from "../../../hooks";
import React from "react";
import { FacebookProvider, Comments } from "react-facebook";
import styles from "./FacebookComments.module.scss";

type Props = {
  postTitle: string,
  postSlug: string,
};

const FacebookComments = ({ postSlug }: Props) => {
  const { url, facebookAppId } = useSiteMetadata();

  return (
    <div className={styles["fbcomments"]}>
      <FacebookProvider appId={facebookAppId} colorScheme="dark">
        <Comments href={url + postSlug} />
      </FacebookProvider>
    </div>
  );
};

export default FacebookComments;
