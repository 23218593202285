// @flow
import { useStaticQuery, graphql } from 'gatsby';

const useCategoriesListAll = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query CategoriesListAllQuery {
        allMdx {
          group(field: frontmatter___category) {
            fieldValue
            totalCount
          }
        }
      }
    `
  );

  return allMdx.group;
};

export default useCategoriesListAll;
