// @flow
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import type { Node as ReactNode } from "react";
import styles from "./Layout.module.scss";
import { MDXProvider } from "@mdx-js/react";
import { shortcodes } from "../../shortcodes/shortcodes";
import useDarkMode from "use-dark-mode";
import { useSiteMetadata } from "../../hooks";

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  pageTitle: string,
};

const Layout = ({ children, title, description }: Props) => {
  const { darkModeSite, blogInfo } = useSiteMetadata();
  const { bio } = blogInfo;
  const setDarkMode = useDarkMode(false);

  useEffect(() => {
    darkModeSite ? setDarkMode.enable() : setDarkMode.disable();
  }, []);

  return (
    <div
      className={description === bio ? styles.layoutHomepage : styles.layout}
    >
      <MDXProvider components={shortcodes}>
        <Helmet>
          <html lang="en"></html>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:site_name" content={title} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={title} />
        </Helmet>
        <Helmet>
          {/*-- Global site tag (gtag.js) - Google Analytics --> */}
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-65325709-4"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "UA-65325709-4");
          `}
          </script>
          {/*-- Mailchimp connect --> */}
          <script id="mcjs">
            {`!function(c,h,i,m,p)
          {
            ((m = c.createElement(h)),
            (p = c.getElementsByTagName(h)[0]),
            (m.async = 1),
            (m.src = i),
            p.parentNode.insertBefore(m, p))
          }
          (document,"script","https://chimpstatic.com/mcjs-connected/js/users/7a0a1f765943974153a9bbace/d49f9eacd21650eb8a00a8c2a.js");`}
          </script>
        </Helmet>
        {children}
      </MDXProvider>
    </div>
  );
};

export default Layout;
