// @flow
import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            author {
              name
              bio
              photo
              url
              contacts {
                email
                twitter
                github
              }
            }
            blogInfo {
              name
              bio
              logo
              logoDark
              contacts {
                email
                twitter
                github
              }
            }
            menu {
              label
              path
              iconSrc
            }
            categoryMetas {
              label
              iconSrc
              description
            }
            url
            title
            subtitle
            copyright
            darkModeSite
            facebookAppId
            disqusShortname
          }
        }
      }
    `
  );

  return site.siteMetadata;
};

export default useSiteMetadata;
