// @flow
import { useStaticQuery, graphql } from 'gatsby';

const useTagsListAll = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query TagsListAllQuery {
        allMdx {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `
  );

  return allMdx.group;
};

export default useTagsListAll;
